import React from "react";
import elydzphoto from "./Assets/elydzphoto.jpg";
import elydzuni from "./Assets/elydzuni.jpg";
import elydzconcert from "./Assets/elydzconcert.jpg";
import "./About.scss";

export class About extends React.Component {
  render() {
    return (
      <div id="about" className="container info-section">
        <div>
          <h1 id="about-me-header">About elydz</h1>
          <div className="container">
            <p>
              elydz (pronounced ee-lidz), aka Dylan, is a producer, artist,
              audio engineer, unicyclist, and aspiring music industry
              professional based in the NYC area. elydz has created Max for Live
              Devices and is interested in incorporating technology into
              performances, developing audio plugins in C++/JUCE, and planning
              and promoting concerts in the New York City area. They are looking
              for work to help develop themselves as an A&R, live sound
              engineer, and concert organizer/promoter, in addition to learning
              about professional artist management.
            </p>
            <div id="elydz-photo-wrapper" class="row">
              <div class="col-12 col-lg-6">
                <img src={elydzuni} alt="elydz" class="elydz-photo img-fluid" />
              </div>
              <div class="col-12 col-lg-6">
                <img
                  src={elydzconcert}
                  alt="elydz"
                  class="elydz-photo img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
