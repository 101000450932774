import instagramlogo from "./SocialIcons/Assets/instagram.png";
import tiktoklogo from "./SocialIcons/Assets/tik-tok.png";
import twitterlogo from "./SocialIcons/Assets/twitter.png";
import beatstartslogo from "./SocialIcons/Assets/beatstars.png";
import traktrainlogo from "./SocialIcons/Assets/traktrain.png";
import spotifylogo from "./SocialIcons/Assets/spotify.png";
import applemusiclogo from "./SocialIcons/Assets/applemusic.png";
import youtubelogo from "./SocialIcons/Assets/youtube.png";
import soundcloudlogo from "./SocialIcons/Assets/soundcloud.png";
import tidallogo from "./SocialIcons/Assets/tidal.png";

let socials = [
  {
    social: "instagram",
    icon: instagramlogo,
    link: "https://www.instagram.com/elydz/",
  },
  {
    social: "tiktok",
    icon: tiktoklogo,
    link: "https://www.tiktok.com/@elydz_",
  },
  {
    social: "twitter",
    icon: twitterlogo,
    link: "https://twitter.com/elydz_",
  },
  {
    social: "beatstars",
    icon: beatstartslogo,
    link: "https://www.beatstars.com/elydz",
  },
  {
    social: "traktrain",
    icon: traktrainlogo,
    link: "https://traktrain.com/elydz",
  },
  {
    social: "spotify",
    icon: spotifylogo,
    link: "https://open.spotify.com/artist/7pz0Of92ILFOxDIWOWGVl7?si=HngU8UD3Qh-GQE8wnrVouw",
  },
  {
    social: "applemusic",
    icon: applemusiclogo,
    link: "https://music.apple.com/us/artist/elydz/1653316096",
  },
  {
    social: "soundcloud",
    icon: soundcloudlogo,
    link: "https://soundcloud.com/elydz",
  },
  {
    social: "youtube",
    icon: youtubelogo,
    link: "https://www.youtube.com/@elydz_/featured",
  },
  {
    social: "tidal",
    icon: tidallogo,
    link: "https://tidal.com/browse/artist/35425983",
  },
];

export default socials;
