import React from "react";
import "./Header.scss";

export class Header extends React.Component {
  render() {
    return (
      <div id="header">
        <a
          href="https://open.spotify.com/track/5mbU5pqn7Q85V73vVWgzFp?si=V4Rj-ctKTMq6OzPhY4Tu_g&context=spotify%3Aalbum%3A1M7v3BdnvlhpPebPhcDgCT"
          target="_blank"
          rel="noopener noreferrer"
        >
          electronic freestyle out now
        </a>
      </div>
    );
  }
}
