import React from "react";
import "./Footer.scss";

export class Footer extends React.Component {
  render() {
    return (
      <div id="footer">
        <p>© elydz 2023</p>
      </div>
    );
  }
}
