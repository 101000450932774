import React from "react";
import Video from "../Video/Video.jsx";
import "./Videos.scss";

export class Videos extends React.Component {
  musicVideoList = [
    {
      link: "https://www.youtube.com/embed/9x_bbCzty68",
      title: "Organization, live sound, & performance by elydz",
    },
    {
      link: "https://www.youtube.com/embed/nb2LkoIYYS4",
      title: "Stereo recording & mixing by elydz",
    },
    {
      link: "https://www.youtube.com/embed/D76MD3fF2_4",
      title: "elydz performance",
    },
  ];
  technicalVideoList = [
    {
      link: "https://www.youtube.com/embed/SSthTjJoLIg",
      title: "Created with C++/JUCE",
    },
    {
      link: "https://www.youtube.com/embed/_1npdS-1Zcw",
      title: "Created with C++ on SHARC Audio Module",
    },
  ];

  renderVideos(videoList) {
    return videoList.map((video) => (
      <div key={video.link} className="my-5">
        <div className="ratio ratio-16x9">
          <Video link={video.link} />
        </div>
        <p className="my-1">{video.title}</p>
      </div>
    ));
  }

  render() {
    return (
      <div id="videos" className="container info-section">
        <div className="row">
          {/* Music & Audio Engineering Experience */}
          <div className="col-12 col-lg-6">
            <h2>Music & Audio Engineering Experience</h2>
            {this.renderVideos(this.musicVideoList)}
          </div>

          {/* Technical Audio Engineering */}
          <div className="col-12 col-lg-6">
            <h2>Technical Audio Engineering</h2>
            {this.renderVideos(this.technicalVideoList)}
          </div>
        </div>
      </div>
    );
  }
}
