import React from "react";
import Song from "../Song/Song.jsx";
import "./Singles.scss";

export class Singles extends React.Component {
  singleList = [
    "https://open.spotify.com/embed/track/51jzSN5SWDhLosAzY88yzo?utm_source=generator",
    "https://open.spotify.com/embed/track/4Wr39cOHEjMlyboU1i3z7f?utm_source=generator",
    "https://open.spotify.com/embed/track/7bF2KqZkVQEDJQUqlVIont?utm_source=generator",
    "https://open.spotify.com/embed/track/4LH1ZLrFyiuy8Pk99I5yGE?utm_source=generator",
    "https://open.spotify.com/embed/track/0DQ83Tu3UwsWih0wJx3CVI?utm_source=generator",
  ];

  render() {
    return (
      <div id="singles" className="container info-section">
        <h1>Singles</h1>
        <div id="singles-area" className="container">
          <div className="row">
            {this.singleList.map((src, index) => (
              <div
                className="col-xl-4 col-lg-6 col-md-10 col-xs-12 song-wrapper"
                key={src}
              >
                <Song link={src} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
