import React from "react";
import "./Video.scss";
import { ScaleLoader } from "react-spinners";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      video: props.link,
      loading: true,
    };
  }

  handleLoad = () => {
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className="video-component ratio ratio-16x9">
        {this.state.loading ? (
          <div className="loader-wrapper">
            <ScaleLoader color="#75fb4c" className="loader" />
          </div>
        ) : null}
        <iframe
          className={this.state.loading ? "hidden" : "song"}
          src={this.state.video}
          allowfullscreen
          onLoad={this.handleLoad}
        />
      </div>
    );
  }
}
