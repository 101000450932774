import React from "react";
import { SocialIcons } from "../SocialIcons/SocialIcons";
import elydzlogo from "../../Assets/elydz_full.png";
import DownArrow from "./Assets/DownArrow.gif";
import "./Home.scss";

export class Home extends React.Component {
  scrollToNavBar = () => {
    const navBar = document.getElementById("nav-bar");
    const headerHeight =
      4 * parseFloat(getComputedStyle(document.documentElement).fontSize); // assuming your root font-size is 16px
    const navBarTop =
      navBar.getBoundingClientRect().top + window.pageYOffset - headerHeight;
    window.scroll({
      top: navBarTop,
      behavior: "smooth",
    });
  };
  render() {
    return (
      <div id="home">
        <div className="container" id="home-content">
          <a
            href="https://linktr.ee/elydz"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={elydzlogo}
              alt="elydz"
              id="elydz-logo"
              className="img-fluid"
            />
          </a>
          <SocialIcons />
          <button onClick={this.scrollToNavBar}>
            <img src={DownArrow} id="home-down-arrow" />
          </button>
        </div>
      </div>
    );
  }
}
