import React from "react";
import Song from "../Song/Song.jsx";
import "./Productions.scss";

export class Productions extends React.Component {
  singleList = [
    "https://open.spotify.com/embed/track/4Zinbk1OWX8z9rZHUndh7I?utm_source=generator",
    "https://open.spotify.com/embed/track/6N5E3j8pfEwPzeaVQRAKpV?utm_source=generator",
    "https://open.spotify.com/embed/track/0LaF5YfWVQPq2JGbv7q9Sw?utm_source=generator",
    "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1596737925&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1571490622&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
    "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1535675350&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true",
  ];

  render() {
    return (
      <div id="productions" className="container info-section">
        <h1>Produced by elydz</h1>
        <div id="productions-area" className="container">
          <div className="row">
            {this.singleList.map((src, index) => (
              <div
                className="col-xl-4 col-lg-6 col-md-10 col-xs-12 song-wrapper"
                key={src}
              >
                <Song link={src} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
