import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./NavBar.scss";

export function NavBar() {
  const location = useLocation();

  function isActive(pathname) {
    return location.pathname === pathname ? "active-link" : "";
  }

  return (
    <div id="nav-bar">
      <div id="nav-content" className="container">
        <nav>
          <ul id="nav-list">
            <li className="nav-item">
              <Link to="/" className={"nav-link " + isActive("/")}>
                Singles
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/videos" className={"nav-link " + isActive("/videos")}>
                Videos
              </Link>
            </li>

            <li className="nav-item">
              <Link
                to="/productions"
                className={"nav-link " + isActive("/productions")}
              >
                Productions
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className={"nav-link " + isActive("/about")}>
                About
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
