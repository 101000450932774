import "./App.scss";
import {
  Header,
  Home,
  NavBar,
  About,
  Videos,
  Singles,
  Productions,
  Footer,
} from "./Components/Components";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route index element={<Singles />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/about" element={<About />} />
          <Route path="/productions" element={<Productions />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Outlet />
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
