import React from "react";
import socials from "../Socials";
import "./SocialIcons.scss";

export class SocialIcons extends React.Component {
  render() {
    return (
      <div id="social-icons" className="container">
        {socials.map((social, index) => (
          <div key={social.social} className="icon-background">
            <a href={social.link} target="_blank" rel="noopener noreferrer">
              <img src={social.icon} alt={social.social} className="icon-img" />
            </a>
          </div>
        ))}
      </div>
    );
  }
}
